/* Import ReactJs */
import React from 'react'

/* Import style */
import './Home.css'

export default function Home() {


    return (
        <main className='homeSection'>
            <img src="./ressource/marina.jpg" />
        </main>

    )
}
