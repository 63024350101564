import React from 'react'
import './Events.css'

export default function Events() {
  return (
    <section className='sectionEvents'>

    </section>
  )
}
